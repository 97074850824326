@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&display=swap');
.App {

}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.marTop {
    margin-top: 65px;
}

.h-header {
    min-height: 64px;
}

.cal-header-hr {
    height: 1px;
    width: 100%;
    background-color: #ccc;
    position: absolute;
    left: -1px
}

.fc .fc-col-header-cell-cushion {
    width: 100%;
}

.cal-header-today {
    background-color: #000000;
    color: #ffffff;
    font-weight: normal;
    margin-bottom: 2px;
}

.cal-header-normalDay {
    background-color: #ffffff;
    color: #000000;
    font-weight: normal;
}

.cal-header-weekend {
    color: red !important;
}

.cal-header-weekday {
    font-weight: normal;
    color: #000000;
    margin-top: 2px;
}

.slick-next:before, .slick-prev:before {
    display: none;
    opacity: 0;
}

.clearfix:after {
    clear: both;
    content: "";
    display: block;
    width: 0px;
    height: 0px;
}
.liveStreamComponent {
    font-family: 'Montserrat', sans-serif !important;
}
.fontMontserrat {
    font-family: 'Montserrat', sans-serif !important;
}

.ant-message {
    z-index: 3000 !important;
}
